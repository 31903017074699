$day_item_percent: 100% / 7;

.rdp__title {
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
}

.rdp__title-center {
  flex: 1;
}

.rdp__days-item,
.rdp__labels-item,
.rdp__months-item {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  vertical-align: top;
  user-select: none;
  cursor: pointer;
}

.rdp__months-item {
  flex: 1;
}

.rdp__months-item-active,
.rdp__days-item-active,
.rdp__days-item-active--start,
.rdp__days-item-active--end,
.rdp__days-item-active--single,
.rdb__days-item-active--connect {
  background-color: #4D9DE0;
  color: #fff;
}

.rdp__days-item-active--single {
  background-color: red;
  border-radius: 50% !important;
}

.rdp__days-item-active--range-connect,
.rdp__days-item-active--range-start,
.rdp__days-item-active--range-end {
  border: 1px solid #4D9DE0;
}

.rdp__days-item-active--range-connect {
  border-left: none;
  border-right: none;
}

.rdp__days-item-active--range-connect,
.rdb__days-item-active--connect {
  border-radius: initial;
}

.rdp__days-item-active--range-end {
  border-left: none !important;
}

.rdp__days-item-active--range-start {
  border-right: none !important;
}

.rdp__days-item-active--range-start.rdp__days-item-active--range-end {
  border-radius: 0 !important;
}

.rdp__days-item-active--range-start,
.rdp__days-item-active--start {
  border-radius: 50% 0 0 50%;
}

.rdp__days-item-active--range-end,
.rdp__days-item-active--end {
  border-radius: 0 50% 50% 0;
}

.rdp__days-item-active--range-start.rdp__days-item-active--range-end,
.rdp__days-item-active--start.rdp__days-item-active--end {
  border-radius: 50%;
}

.rdp__days-item--grey {
  color: #e9e9e9;
}

.rdp__body {
  position: relative;
  display: flex;
}

.rdp__months-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rdp__view {
  display: inline-block;
  vertical-align: top;
  flex: 1;
}

.rdp--hidden {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.rdp__animation-left {
  transition: transform 0.4s ease;
}

.rdp__animation-right {
  transition: transform 0.4s ease;
}

.rdp__labels,
.rdp__days-row,
.rdp__months-row {
  display: flex;
  justify-content: center;
}

.rdp__months-row {
  flex: 1;
  align-items: center;
}

.rdp__days-row {
  margin: 4px 0;
}

.rdp__prev-btn,
.rdp__next-btn {
  width: 16px;
  height: 16px;
  background: {
    size: cover;
    repeat: no-repeat;
    position: center;
  }
  cursor: pointer;
}

.rdp__prev-btn {
  float: left;
  background-image: url('../images/prev.png')
}

.rdp__next-btn {
  float: right;
  background-image: url('../images/next.png')
}