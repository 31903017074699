.month-enter {
  transform: translate3d(0, -100%, 0);
}

.month-enter-active {
  transform: translate3d(0, 0, 0);
  transition: transform 300ms ease-in;
}

.month-exit {
  transform: translate3d(0, 0, 0);
}

.month-exit-active {
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms ease-in;
}